.returned-secret {
    position: relative;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    color: rgb(255, 255, 255);
    text-align: center;

    width: 100%;
}

@media (max-width: 640px) {
    .returned-secret {
        font-size: 14px;
    }
    .lds-ring {
        transform: translate3d(-50%, 0%, 0) scale(0.5);
    }
}

@media (min-width: 640px) {
    .lds-ring {
        transform: translate3d(-50%, 0%, 0);
    }
}

.component-returnedSecret {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;

    bottom: 15px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.lds-ring {
    display: inline-block;
    position: absolute;
    width: 80px;
    height: 80px;

    left: 50%;

    bottom: 15px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  