.component-info {
    position: fixed;
    text-align: right;
    padding: 0 2em;

    width: 100%;

    top: 15px;
    left: 0;

    display: flex; 
    flex-direction: column;
    align-items: flex-end;
}

p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5em;
    color:   white !important;
    margin: 0;
    margin-bottom: 0 !important;
}

.component-infoText {
    position: relative;
    font-family: 'Poppins', sans-serif;
    font-size: 1.5em;
    color: rgb(255, 255, 255);
}

@media (max-width: 640px) {
    .component-infoText {
        font-family: 'Poppins', sans-serif;
        font-size: 1em;
        color: rgb(255, 255, 255);
    }

    p {
        font-family: 'Poppins', sans-serif;
        font-size: 1em;
        color:   white !important;
        margin: 0;
        margin-bottom: 0;
    }
}