
.SecretEnter{
    position: relative;
}
.component-secret-entry {
    position: relative;
    top: 50%;
    left: 50%;
    
    transform: translate3d(-50%, -50%, 0);

    text-align: center;
    padding-left: 1em;
    padding-right: 1em;

    max-width: 1000px;

}
.component-form{
    border-radius: 45px;
    width: 40em;
    height: 3em;
    background-color: rgba(255, 255, 255, 0.7);
    font-family: 'Poppins', sans-serif;
    font-size: 2em;
    color: rgb(100, 100, 100);
}

.btn-outline-primary {
    border-color: white !important;
    color: white !important;
}

.btn-outline-primary:hover {
    color: rgba(252,176,69,1) !important;
    background-color: white !important

}

.form-control {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5em !important;
    max-height: 15em !important;
    min-height: 2em !important;
}


@media (max-width: 640px) {
    .form-control {
        font-family: 'Poppins', sans-serif;
        font-size: 1em !important;
        height: 10em !important;
    }

    
  
}
  


