.outer-container {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
}

#root {
  position: relative;
  width: 100%;
  height: calc(100 * var(--vh, 1vh));
}
